import rules from "../../assets/2024-pdf/rules.pdf";
import bottomImage from "../../assets/images/2024-bottom-fixed.png";
import mainImage from "../../assets/images/2024-main-fixed.png";
import { SelectorButton } from "../selector-button";

const nav = [
  { title: "Balvas", href: "#prices" },
  { title: "Noteikumi", href: rules },
];

export default function Dynamit() {
  return (
    <>
      <SelectorButton />

      <a
        href='https://wa.me/37122541151'
        className='fixed bottom-[24px] right-[24px] group flex row flex-row items-center gap-2'
      >
        <span className=' transition-all uppercase font-bold opacity-0 group-hover:opacity-100 text-lg'>
          sazināties ar mums whatsapp
        </span>

        <img
          src='https://c.sales.lv/i/t-62783/wa37122541151.png'
          alt='Whatsapp'
          className='w-[48px]'
        />
      </a>

      <div className='flex flex-col items-center md:px-8 px-4 bg-lightning py-8 bg-cover bg-center bg-no-repeat'>
        <img
          src={mainImage}
          alt='Dynamit 2024'
          className='lg:max-w-[80%]'
          draggable={false}
        />

        <ul className='flex flex-row items-center gap-4 mb-8 md:-top-12 -top-4 relative'>
          {nav.map(({ title, href }) => (
            <li>
              <a
                href={href}
                className='font-AcuminProExtraCondMedium text-2xl uppercase underline md:text-4xl text-yel'
              >
                {title}
              </a>
            </li>
          ))}
        </ul>

        <h1
          id='prizes'
          className='font-AcuminProExtraCondMedium uppercase underline text-4xl text-yel mb-4'
        >
          Balvas
        </h1>
        <img
          src={bottomImage}
          alt='Dynamit 2024'
          className='lg:max-w-[60%] pb-8'
          draggable={false}
        />
      </div>

      <div
        className='w-full py-8 flex justify-center items-center italic'
        style={{ fontFamily: "Inter" }}
      >
        <a
          href='https://integrators.lv?utm_source=dynamit.lv'
          target='_blank'
          rel='noreferrer'
          className='font-bold hover:text-blue-600 transition-colors'
        >
          Izstrādāja INTEGRATORS.lv
        </a>
      </div>
    </>
  );
}
