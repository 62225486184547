import styled from 'styled-components';

const size = {
	xs: '320px',
	md: '768px',
	sm: '960px',
	lg: '1200px',
};

const device = {
	xs: `(min-width: ${size.xs})`,
	md: `(min-width: ${size.md})`,
	sm: `(min-width: ${size.sm})`,
	lg: `(min-width: ${size.lg})`
};

// export const Sample = styled.div`
// 	@media only screen and ${device.xs}{
// 	}
// 	@media only screen and ${device.sm}{	
// 	}
// 	@media only screen and ${device.lg}{	
// 	}
// `;

export const Wrapper = styled.div`
	margin: 100px 0px;
	.div__prizes-flex {
		display: flex;
		flex-direction: column;
		align-items: baseline;
		justify-content: center;
		.div__prize-item {
			display: flex;
			flex-direction: column;
			align-items: center;
			text-align: center;
			margin: 0px auto 40px auto;

			h3 {
				color: #fef200;
				width: 175px;
				margin: 20px 0px 0px 0px;
				font-family: UniSansHeavyCAPS;
				text-shadow: 1px 1px 0px #000, -1px -1px 0px #000, -1px 1px 0px #000, 1px -1px 0px #000,  0.75px 0.75px 0px #000, -0.75px -0.75px 0px #000, -0.75px 0.75px 0px #000, 0.75px -0.75px 0px #000, 0.9px 0.9px 0px #000, -0.9px -0.9px 0px #000, -0.9px 0.9px 0px #000, 0.9px -0.9px 0px #000;
				.span__number {
					font-size: 23px;
					margin: 0px 3px 0px 0px;
					text-shadow: 1px 1px 0px #000, -1px -1px 0px #000, -1px 1px 0px #000, 1px -1px 0px #000,  0.75px 0.75px 0px #000, -0.75px -0.75px 0px #000, -0.75px 0.75px 0px #000, 0.75px -0.75px 0px #000, 0.9px 0.9px 0px #000, -0.9px -0.9px 0px #000, -0.9px 0.9px 0px #000, 0.9px -0.9px 0px #000;
				}
			}
		}
	}

	@media only screen and ${device.xs}{
	}
	@media only screen and ${device.sm}{	
		margin: 100px 0px;
	.div__prizes-flex {
		display: flex;
		flex-direction: row;
		align-items: baseline;
		justify-content: space-evenly;
		.div__prize-item {
			display: flex;
			flex-direction: column;
			align-items: center;
			text-align: center;
			margin: 0px 20px 0px 0px;
			&:nth-last-child(1) {
				margin-right: 0px;
			}

			h3 {
				color: #fef200;
				width: 175px;
				margin: 20px 0px 0px 0px;
				font-family: UniSansHeavyCAPS;
				
				.span__number {
					font-size: 23px;
					margin: 0px 3px 0px 0px;
				}
			}
		}
	}
	}
	@media only screen and ${device.lg}{	
	}
`;

export const Title = styled.h1`
	font-family: PFDinTextCompPro-Regular;
	color: #fef200;
	background: #000000; 
	width: fit-content;
	padding: 17px 100px;
	margin: 0px auto 30px auto;
	
	@media only screen and ${device.xs}{
	}
	@media only screen and ${device.sm}{	
	}
	@media only screen and ${device.lg}{	
	}
`;

