import styled from 'styled-components';
import background_image from "../../assets/images/background_image.png"

const size = {
	xs: '320px',
	md: '768px',
	sm: '960px',
	lg: '1200px',
};

const device = {
	xs: `(min-width: ${size.xs})`,
	md: `(min-width: ${size.md})`,
	sm: `(min-width: ${size.sm})`,
	lg: `(min-width: ${size.lg})`
};

// export const Sample = styled.div`
// 	@media only screen and ${device.xs}{
// 	}
// 	@media only screen and ${device.sm}{	
// 	}
// 	@media only screen and ${device.lg}{	
// 	}
// `;

export const Wrapper = styled.div`
	font-family: PFDinTextCompPro-Regular;
	display: flex;
	flex-direction: column;
	justift-content: center;
	img {
		margin: 0px auto 50px auto;
		width: 250px;
	}
	ul {
		list-style: none;
		display: inline-flex;
		flex-direction: row;
		justify-content: center;
		width: 100%;
		li {
			margin-right: 15px;
			cursor: pointer;
		}
		li:nth-last-child(1) {
			margin: 0px;
		}
	}

	a, .li__nav-rules{
		text-decoration: none;
		color: #fef200;
		font-size: 22px;
		font-weight: normal;

	}

	.li__nav-rules {
		display: flex;
		position: relative;
		&:hover {
			text-decoration: none;
		}	
	}

	.img__navbar-dropdown {
		display: block;
		width: 24px;
		transition: 0.25s ease-in-out;
	}

	.div__rule-dropdown {
		width: 100%;
		display: flex;
		justify-content: center;
		position: absolute;
		top: 25px;
		z-index: 99999;
		right: 0px;
		
		ul {
			display: flex;
			flex-direction: column;
			list-style: none;
			width: fit-content;
			background-color: black;
			width: 110px;
			padding: 20px 10px 20px 0px;

			li {
				font-size: 22px;
				text-align: right;
				margin: 5px 0px;
				color: #fef200;

				&:hover {
					text-decoration: underline;
				}

				&:nth-last-child(1) {
					margin: 0px;
				}
				
			}
		}
	}

	@media only screen and ${device.xs}{
	}
	@media only screen and ${device.sm}{	
		font-family: PFDinTextCompPro-Regular;
		display: flex;
		img {
			display: none;
		}
		ul {
			list-style: none;
			display: inline-flex;
			flex-direction: row;
			justify-content: flex-end;
			width: 100%;
			li {
				margin-right: 40px;
			}
			li:nth-last-child(1) {
				margin: 0px;
			}
		}

		a, .li__nav-rules {
			text-decoration: none;
			color: #fef200;
			font-size: 26px;
			font-weight: normal;

		}

		.img__navbar-dropdown {
			display: block;
			width: 24px;
			transition: 0.25s ease-in-out;
		}
	
		.div__rule-dropdown {
			display: flex;
			justify-content: flex-end;
			position: absolute;
			top: 30px;
			z-index: 99999;
			
			ul {
				display: flex;
				flex-direction: column;
				list-style: none;
				width: fit-content;
				background-color: black;
				width: 125px;
				padding: 20px 10px 20px 0px;
	
				li {
					font-size: 22px;
					text-align: right;
					margin: 5px 0px;
					color: #fef200;
	
					&:hover {
						text-decoration: underline;
					}
					&:nth-last-child(1) {
						margin: 0px;
					}
					
				}
			}
		}
		
	}
	@media only screen and ${device.lg}{	
	}
`;

export const BackgroundOverlay = styled.span`

	@media only screen and ${device.xs}{
	}
	@media only screen and ${device.sm}{	
	}
	@media only screen and ${device.lg}{	
	}
`;
