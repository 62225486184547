import styled from "styled-components";

const size = {
  xs: "320px",
  md: "768px",
  sm: "960px",
  lg: "1024px",
};

const device = {
  xs: `(min-width: ${size.xs})`,
  md: `(min-width: ${size.md})`,
  sm: `(min-width: ${size.sm})`,
  lg: `(min-width: ${size.lg})`,
};

// export const Sample = styled.div`
// 	@media only screen and ${device.xs}{
// 	}
// 	@media only screen and ${device.sm}{
// 	}
// 	@media only screen and ${device.lg}{
// 	}
// `;

export const Wrapper = styled.div`
  font-family: UniSansHeavyCAPS;
  /* display: flex; */
  /* width: 100%; */
  /* height: fit-content; */
  /* flex-direction: column; */
  /* justify-content: center; */
  margin: 50px auto 0px auto;


  .div__cans-image {
    // display: none;
    margin: 0px auto 50px auto;
    img {
      width: 200px;
    }
  }

  .div__main-title-info {
    // background-color: red;
    font-family: UniSansHeavyCAPS;
    display: flex;
    width: fit-content;
    height: fit-content;
    display: flex;
    flex-direction: column;
    position: relative;
    margin: 0px auto 40px auto;
    width: 100%;
  }

  .img__new-tastes {
    margin: 0px auto 10px auto;
    max-width: 320px;
    width: 100%;
  }

  h1 {
    font-size: 31px;
    width: fit-content;
    margin: 0px auto 10px auto;
  }

  .img__prizes-image {
    width: 250px;
    margin: 20px auto 20px auto;
  }

  .div__prizes-list {
    display: flex;
    flex-direction: column;
    // width: 400px;
    text-align: center;

    .div__inner-column {
      margin: 0px 0px 0px 0px;
      .span__number {
        font-size: 22px;
        padding: 0px 2px 0px 0px;
      }
      h4 {
        font-size: 20px;
        color: #fef200;
        margin: 0px 0px 5px 0px;
        text-shadow: 1px 1px 0px #000, -1px -1px 0px #000, -1px 1px 0px #000,
          1px -1px 0px #000, 0.75px 0.75px 0px #000, -0.75px -0.75px 0px #000,
          -0.75px 0.75px 0px #000, 0.75px -0.75px 0px #000, 0.9px 0.9px 0px #000,
          -0.9px -0.9px 0px #000, -0.9px 0.9px 0px #000, 0.9px -0.9px 0px #000;
      }
    }
  }

  @media only screen and ${device.xs} {
  }
  @media only screen and ${device.sm} {
    // background-color: blue;

    font-family: UniSansHeavyCAPS;
    display: flex;
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin: 50px 0px 0px 0px;

    .div__cans-image {
      // background-color: yellow;
      display: block;
      position: relative;
      top: -50px;
      margin: 0px 50px 0px 0px;
      img {
        width: 544px;
      }
    }

    .div__main-title-info {
      // background-color: red;
      font-family: UniSansHeavyCAPS;
      display: flex;
      width: fit-content;
      height: fit-content;
      display: flex;
      flex-direction: column;
      position: relative;
    }

    .img__new-tastes {
      margin: 0px 0px 30px 0px;
      width: 453px;
    }

    h1 {
      font-size: 39px;
    }

    .img__prizes-image {
      width: 301px;
      margin: 20px 0px 0px 0px;
    }

    .div__prizes-list {
      display: flex;
      flex-direction: row;
      position: absolute;
      left: -125px;
      top: 100%;
      text-align: left;
      width: auto;

      text-align: left;

      .div__inner-column {
        width: 275px;
        margin: 0px 0px 0px 0px;
        &:nth-child(1) {
          margin: 0px 40px 0px 0px;
        }
        .span__number {
          font-size: 22px;
          padding: 0px 4px 0px 0px;
        }
        h4 {
          font-size: 22px;
          color: #fef200;
        }
      }
    }
  }
  @media only screen and ${device.lg} {
  }
`;
