import { zodResolver } from "@hookform/resolvers/zod";
import axios from "axios";
import moment from "moment";
import React from "react";
import { FieldError, useForm, UseFormRegister } from "react-hook-form";
import * as z from "zod";
import rules from "../../assets/2024-pdf/rules-narvesen.pdf";
import { API_BASE_URL } from "../../config";

export const registrationSchema = z.object({
  first_name: z
    .string({ required_error: "* Vārds ir nepieciešams" })
    .min(2, { message: "* Nepieciešams garāks par 2 simboliem" })
    .max(32),
  last_name: z
    .string({ required_error: "* Uzvārds ir nepieciešams" })
    .min(2, { message: "* Nepieciešams garāks par 2 simboliem" })
    .max(32),
  email: z
    .string({ required_error: "* E-pasts ir nepieciešams" })
    .email({ message: "* Ievadiet pareizu e-pastu" }),
  phone_number: z
    .string({ required_error: "* Tālrunis ir nepieciešams" })
    .length(8, { message: "* Tālrunī jābūt 8 cipariem" }),
  age: z
    .number({
      required_error: "* Vecums ir nepieciešams",
      invalid_type_error: "* Nederīgs",
    })
    .min(18, { message: "* Vecumam jābūt virs 18 gadiem" }),
  rules: z.literal<boolean>(true, {
    errorMap: () => ({ message: "* Nepieciešams piekrist noteikumiem" }),
  }),
  subscribe: z.literal<boolean>(true, {
    errorMap: () => ({ message: "* Nepieciešams apstiprināt datu apstrādi" }),
  }),
  // receipt_number: z
  //   .string({ required_error: "* Čeka numurs ir nepieciešams" })
  //   .min(4, { message: "* Čeka numurs ir nepieciešams" }),
});

type FormData = z.infer<typeof registrationSchema>;

export default function RegisterNew() {
  const form = useForm<FormData>({
    resolver: zodResolver(registrationSchema),
    defaultValues: {},
    mode: "onChange",
  });

  const formErrors = form.formState.errors;

  const resetForm = () => {
    form.reset({
      first_name: "",
      last_name: "",
      email: "",
      phone_number: "",
      age: "" as any,
      rules: false,
      subscribe: false,
    });
  };

  const [IP, setIP] = React.useState<string>("127.0.0.1");

  const getIP = async () => {
    try {
      const res = await axios.get("https://geolocation-db.com/json/");
      setIP(res.data.IPv4);
    } catch (err: any) {
      console.error("ERROR: getIP", err);
    }
  };

  React.useEffect(() => {
    getIP();
  }, []);

  const handleLogin = async (
    data: {
      first_name: string;
      last_name: string;
      email: string;
      phone_number: string;
      age: number;
      rules: boolean;
      subscribe: boolean;
    },
    e?: React.BaseSyntheticEvent
  ) => {
    e?.preventDefault();

    try {
      await axios.post(`${API_BASE_URL}/dynamit/register`, { ...data, ip: IP });

      resetForm();

      alert("Paldies, reģistrācija veiksmīga!");
    } catch (err: any) {
      console.error("ERROR: onSubmit:", err);

      alert(
        `Kaut kas nogāja greizi, mēģini vēlreiz. ${err?.response?.data?.data?.message}`
      );
    }
  };

  const start = moment("2024-03-31", "YYYY-MM-DD");
  const end = moment("2024-04-28", "YYYY-MM-DD");

  const registrationOpen = moment() >= start && moment() <= end;

  return (
    <div className="flex flex-col items-center py-8">
      <div className="px-16 py-4 mx-auto bg-black">
        <p
          id="register"
          className="uppercase font-PFDinTextCompPro-Regular text-yel text-4xl"
        >
          reģistrēties
        </p>
      </div>

      <div className="max-w-[768px] px-8 mx-auto py-8">
        {/* <Form {...form}> */}
        <form
          onSubmit={form.handleSubmit(handleLogin)}
          className=" flex flex-col gap-6"
        >
          <div className="grid md:grid-cols-2 grid-cols-1 gap-2">
            <FormField
              name="first_name"
              type="text"
              placeholder="Vārds"
              register={form.register}
              error={formErrors.first_name}
              disabled={!registrationOpen}
            />

            <FormField
              name="last_name"
              type="text"
              placeholder="Uzvārds"
              register={form.register}
              error={formErrors.last_name}
              disabled={!registrationOpen}
            />

            <div className="grid grid-cols-4 gap-2">
              <FormField
                name="age"
                type="number"
                placeholder="Vecums"
                register={form.register}
                error={formErrors.age}
                valueAsNumber={true}
                className="col-span-1"
                disabled={!registrationOpen}
              />
              <FormField
                name="phone_number"
                type="tel"
                placeholder="Tālrunis"
                register={form.register}
                error={formErrors.phone_number}
                className="col-span-3"
                disabled={!registrationOpen}
              />
            </div>

            <FormField
              name="email"
              type="text"
              placeholder="E-pasts"
              register={form.register}
              error={formErrors.email}
              disabled={!registrationOpen}
            />

            {/* <FormField
              name="receipt_number"
              type="text"
              placeholder="Čeka numurs"
              register={form.register}
              error={formErrors.receipt_number}
            /> */}
          </div>

          <div className="grid grid-cols-1 gap-4 md:gap-0 md:grid-cols-2">
            <div className="col-span-1 flex flex-col gap-2">
              <div className="flex flex-col">
                <div className="flex flex-row gap-2 items-center">
                  <input
                    type="checkbox"
                    {...form.register("rules")}
                    className="w-6 h-6 "
                    disabled={!registrationOpen}
                  />
                  <label
                    htmlFor="rules"
                    className="font-PFDinTextCompPro-Regular text-yel"
                  >
                    Es piekrītu kampaņas{" "}
                    <a href={rules} className="underline text-red">
                      noteikumiem
                    </a>
                  </label>
                </div>
                {formErrors.rules && (
                  <p className="text-red-600 bg-yel font-bold px-1 py-1 rounded-b-lg text-sm">
                    {formErrors.rules.message}
                  </p>
                )}
              </div>

              <div className="flex flex-col">
                <div className="flex flex-row gap-2 items-center">
                  <input
                    type="checkbox"
                    {...form.register("subscribe")}
                    className="w-6 h-6"
                    disabled={!registrationOpen}
                  />
                  <label
                    htmlFor="subscribe"
                    className="font-PFDinTextCompPro-Regular text-yel"
                  >
                    Piekrītu datu apstrādes noteikumiem*
                  </label>
                </div>
                {formErrors.subscribe && (
                  <p className="text-red-600 bg-yel font-bold px-1 py-1 rounded-b-lg text-sm">
                    {formErrors.subscribe.message}
                  </p>
                )}
              </div>
            </div>

            <div className="col-span-1 flex justify-start md:justify-end items-center">
              <button
                disabled={!registrationOpen}
                className="bg-transparent border-4 border-yel px-8 py-2 h-[56px] disabled:opacity-50"
              >
                <span className="uppercase text-yel text-2xl font-PFDinTextCompPro-Regular">
                  {registrationOpen
                    ? "reģistrēties"
                    : "reģistrācija no 31.marta"}
                </span>
              </button>
            </div>
          </div>

          <p className="text-sm font-PFDinTextCompPro-Regular text-yel">
            *Piekrītu savu personas datu apstrādei konkursā, lai saņemtu
            komerciālus paziņojumus, reklāmas sūtījumus, īpašos piedāvājumus vai
            līdzīgus piedāvājumus un pakalpojumus uz savu norādīto e-pasta
            adresi no AS “Cēsu alus”. Dati tiks uzglabāti 2 gadus vai līdz
            atteikšanās brīdim. Ar šo apstiprinu, ka esmu informēts par tiesībām
            iepazīties ar saviem iesniegtajiem un apstrādātājiem datiem, labot
            tos, saņemt un pārsūtīt tos citam pārzinim, ierobežot to apstrādi
            vai atteikties no datu apstrādes atsaucot savu sniegto piekrišanu,
            kā arī pieprasīt datu dzēšanu. Apliecinu, ka iesniegtie dati ir
            mani, tie ir precīzi un patiesībai atbilstoši.
          </p>
        </form>
        {/* </Form> */}
      </div>
    </div>
  );
}

export type ValidFieldNames =
  | "first_name"
  | "last_name"
  | "email"
  | "age"
  | "phone_number";
// | "receipt_number";

type FormFieldProps = {
  type: string;
  placeholder: string;
  name: ValidFieldNames;
  register: UseFormRegister<FormData>;
  error: FieldError | undefined;
  valueAsNumber?: boolean;
  className?: string;
  disabled?: boolean
};

const FormField: React.FC<FormFieldProps> = ({
  type,
  placeholder,
  name,
  register,
  error,
  valueAsNumber,
  className = "",
  disabled = false,
}) => (
  // <>
  // label
  //   <input
  //     type={type}
  //     placeholder={placeholder}
  //     {...register(name, { valueAsNumber })}
  //   />
  //   {error && <span className="error-message">{error.message}</span>}
  // </>

  <div className={`flex flex-col ${className}`}>
    <label htmlFor={name} className="font-PFDinTextCompPro-Regular text-yel">
      {placeholder}
    </label>
    <input
      type={type}
      {...register(name, { valueAsNumber })}
      disabled={disabled}
      className="outline-none text-black py-2 text-xl px-2 font-PFDinTextCompPro-Regular disabled:opacity-50"
    />
    {error ? (
      <p className="text-red-600 bg-yel font-bold px-1 py-1 rounded-b-lg text-sm">
        {error.message}
      </p>
    ) : null}
  </div>
);
