import { Wrapper } from "./Dates.styled";

import narvesen_rules from "../../assets/2024-pdf/rules-narvesen.pdf";
import narvesen_logo from "../../assets/images/narvesen_logo.png";

import { useLocation, useNavigate } from "react-router-dom";

import moment from "moment";
import { Link } from "react-scroll";

export const dates: Array<{
  name: string;
  title: string;
  logo: any;
  start: moment.Moment;
  end: moment.Moment;
  rules: any;
  text_date: string;
}> = [
  // {
  //   name: "virsi",
  //   title: "Virši",
  //   logo: virsi_logo,
  //   start: moment("29.06.2023", "DD.MM.YYYY"),
  //   end: moment("01.08.2023", "DD.MM.YYYY"),
  //   rules: virsi_rules,
  //   text_date: "29.06 - 31.07.2023",
  // },
  // {
  //   name: "viada",
  //   title: "Viada",
  //   logo: viada_logo,
  //   start: moment("06.06.2023", "DD.MM.YYYY"),
  //   end: moment("04.08.2023", "DD.MM.YYYY"),
  //   rules: viada_rules,
  //   text_date: "06.06 - 03.08.2023",
  // },
  // {
  //   name: "circlek",
  //   title: "Circle K",
  //   logo: circlek_logo,

  //   start: moment("01.06.2023", "DD.MM.YYYY"),
  //   end: moment("20.07.2023", "DD.MM.YYYY"),
  //   rules: circlek_rules,
  //   text_date: "01.06 - 19.07.2023",
  // },
  {
    name: "narvesen",
    title: "Narvesen",
    logo: narvesen_logo,
    start: moment("31.03.2024", "DD.MM.YYYY"),
    end: moment("28.04.2024", "DD.MM.YYYY"),
    rules: narvesen_rules,
    text_date: "31.03 - 27.04.2024",
  }
  // {
  //   name: "rimi",
  //   title: "Rimi",
  //   logo: rimi_logo,
  //   start: moment("11.04.2023", "DD.MM.YYYY"),
  //   end: moment("25.04.2023", "DD.MM.YYYY"),
  //   rules: rimi_rules,
  //   text_date: "11.04 - 24.04.2023",
  // },
  // {
  //   name: "mego",
  //   title: "Mego",
  //   logo: mego_logo,
  //   start: moment("31.07.2023", "DD.MM.YYYY"),
  //   end: moment("14.08.2023", "DD.MM.YYYY"),
  //   rules: mego_rules,
  //   text_date: "31.07 - 13.08.2023",
  // },
  // {
  //   name: "maxima",
  //   title: "Maxima",
  //   logo: maxima_logo,
  //   start: moment("27.06.2023", "DD.MM.YYYY"),
  //   end: moment("11.07.2023", "DD.MM.YYYY"),
  //   rules: maxima_rules,
  //   text_date: "27.06 - 10.07.2023",
  // }
  // {
  //   name: "citro",
  //   title: "Citro",
  //   logo: citro_logo,
  //   start: moment("20.07.2023", "DD.MM.YYYY"),
  //   end: moment("17.08.2023", "DD.MM.YYYY"),
  //   rules: citro_rules,
  //   text_date: "20.07 - 16.08.2023",
  // },
  ,
];

export const Dates = () => {
  const search = useLocation().search;
  const type = new URLSearchParams(search).get("t");

  const navigate = useNavigate();

  return (
    <Wrapper>
      {dates.map((item) => (
        <div
          className={
            `${(item.start.valueOf() <= moment.now() &&
              item.end.valueOf() >= moment.now()) ||
            type === item.name
              ? "div__store-date active"
              : "div__store-date"}`
          }
          
        >
          <div className="div__date-store-inner">
            <a href={item.rules} target="_blank" rel="noopener noreferrer">
              <img src={item.logo} alt={item.title} />
            </a>
            <h5>{item.text_date}</h5>
            {item.start.valueOf() <= moment.now() &&
            item.end.valueOf() >= moment.now() ? (
              <Link
                to="register"
                spy={true}
                smooth={true}
                offset={-50}
                duration={500}
                delay={50}
                className="a__date-store-register-button"
                onClick={() =>
                  item.rules && navigate({ pathname: `?t=${item.name}` })
                }
              >
                <span>REĢISTRĒTIES</span>
              </Link>
            ) : null}
          </div>
        </div>
      ))}

      {/* <div
        className={
          narvesenStart <= moment.now() && narvesenEnd >= moment.now()
            ? "div__store-date active"
            : "div__store-date"
        }
      >
        <div className="div__date-store-inner">
          <a href={narvesen_rules} target="_blank" rel="noopener noreferrer">
            <img src={narvesen_logo} alt="Narvesen" />
          </a>
          <h5>03.04.-30.04.2022.</h5>
          {narvesenStart <= moment.now() && narvesenEnd >= moment.now() && (
            <Link
              to="register"
              spy={true}
              smooth={true}
              offset={-50}
              duration={500}
              delay={50}
              className="a__date-store-register-button"
              onClick={() => navigate({ pathname: "?t=narvesen" })}
            >
              <span>REĢISTRĒTIES</span>
            </Link>
          )}
        </div>
      </div>
      <div
        className={
          virsiStart <= moment.now() && virsiEnd >= moment.now()
            ? "div__store-date active"
            : "div__store-date"
        }
      >
        <div className="div__date-store-inner">
          <a href={virsi_rules} target="_blank" rel="noopener noreferrer">
            <img src={virsi_logo} alt="Virši" />
          </a>
          <h5>28.04.-25.05.2022.</h5>
          {virsiStart <= moment.now() && virsiEnd >= moment.now() && (
            <Link
              to="register"
              spy={true}
              smooth={true}
              offset={-50}
              duration={500}
              delay={50}
              className="a__date-store-register-button"
              onClick={() => navigate({ pathname: "?t=virsi" })}
            >
              <span>REĢISTRĒTIES</span>
            </Link>
          )}
        </div>
      </div>
      <div
        className={
          rimiStart <= moment.now() && rimiEnd >= moment.now()
            ? "div__store-date active"
            : "div__store-date"
        }
      >
        <div className="div__date-store-inner">
          <a href={rimi_rules} target="_blank" rel="noopener noreferrer">
            <img src={rimi_logo} alt="Rimi" />
          </a>
          <h5>03.05.-16.05.2022.</h5>
          {rimiStart <= moment.now() && rimiEnd >= moment.now() && (
            <Link
              to="register"
              spy={true}
              smooth={true}
              offset={-50}
              duration={500}
              delay={50}
              className="a__date-store-register-button"
              onClick={() => navigate({ pathname: "?t=rimi" })}
            >
              <span>REĢISTRĒTIES</span>
            </Link>
          )}
        </div>
      </div>
      <div
        className={
          maximaStart <= moment.now() && maximaEnd >= moment.now()
            ? "div__store-date active"
            : "div__store-date"
        }
      >
        <div className="div__date-store-inner">
          <a href={maxima_rules} target="_blank" rel="noopener noreferrer">
            <img src={maxima_logo} alt="Maxima" />
          </a>
          <h5>31.05.-13.06.2022.</h5>
          {maximaStart <= moment.now() && maximaEnd >= moment.now() && (
            <Link
              to="register"
              spy={true}
              smooth={true}
              offset={-50}
              duration={500}
              delay={50}
              className="a__date-store-register-button"
              onClick={() => navigate({ pathname: "?t=maxima" })}
            >
              <span>REĢISTRĒTIES</span>
            </Link>
          )}
        </div>
      </div>
      <div
        className={
          circlekStart <= moment.now() && circlekEnd >= moment.now()
            ? "div__store-date active"
            : "div__store-date"
        }
      >
        <div className="div__date-store-inner">
          <a href={circlek_rules} target="_blank" rel="noopener noreferrer">
            <img src={circlek_logo} alt="Circle K" />
          </a>
          <h5>02.06.-20.07.2022.</h5>
          {circlekStart <= moment.now() && circlekEnd >= moment.now() && (
            <Link
              to="register"
              spy={true}
              smooth={true}
              offset={-50}
              duration={500}
              delay={50}
              className="a__date-store-register-button"
              onClick={() => navigate({ pathname: "?t=circlek" })}
            >
              <span>REĢISTRĒTIES</span>
            </Link>
          )}
        </div>
      </div>
      <div
        className={
          viadaStart <= moment.now() && viadaEnd >= moment.now()
            ? "div__store-date active"
            : "div__store-date"
        }
      >
        <div className="div__date-store-inner">
          <a href={viada_rules} target="_blank" rel="noopener noreferrer">
            <img src={viada_logo} alt="Viada" />
          </a>
          <h5>06.09.-03.10.2022.</h5>
        </div>
        {viadaStart <= moment.now() && viadaEnd >= moment.now() && (
          <Link
            to="register"
            spy={true}
            smooth={true}
            offset={-50}
            duration={500}
            delay={50}
            className="a__date-store-register-button"
            onClick={() => navigate({ pathname: "?t=viada" })}
          >
            <span>REĢISTRĒTIES</span>
          </Link>
        )}
      </div> */}
    </Wrapper>
  );
};
