import prize_bag_image from "../../assets/images/prize_bag.png";
import prize_cold_bag_image from "../../assets/images/prize_cold_bag.png";
import prize_jbl_image from "../../assets/images/prize_jbl.png";
import prize_xbox_image from "../../assets/images/prize_xbox.png";
import { Title, Wrapper } from "./Prizes.styled";

export const Prizes = () => {
  return (
    <Wrapper id="prizes">
      <Title className="text-4xl">BALVAS</Title>

      <div className="div__prizes-flex">
        <div className="div__prize-item">
          <img src={prize_xbox_image} alt="Microsoft Xbox Series X 1TB" />
          <h3>
            <span className="span__number">1</span>Microsoft Xbox Series X 1TB
          </h3>
        </div>

        <div className="div__prize-item">
          <img src={prize_jbl_image} alt="JBL TUMBIŅAS" />
          <h3>
            <span className="span__number">5</span>JBL TUMBIŅAS
          </h3>
        </div>

        <div className="div__prize-item">
          <img src={prize_cold_bag_image} alt="AUKSTUMA SOMAS" />
          <h3>
            <span className="span__number">6</span>AUKSTUMA SOMAS
          </h3>
        </div>

        <div className="div__prize-item">
          <img src={prize_bag_image} alt="MUGURSOMAS" />
          <h3>
            <span className="span__number">9</span>MUGURSOMAS
          </h3>
        </div>

       
      </div>
    </Wrapper>
  );
};
