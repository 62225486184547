import "./assets/css/main.css";

import ReactDOM from "react-dom";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import Admin from "./admin";
import Selector from "./new";
import Dynamit from "./new/dynamit";
import Narvesen from "./new/narvesen";

ReactDOM.render(
  // <React.StrictMode>
  <BrowserRouter>
    <Routes>
      <Route path="/" element={<Selector />} />

      <Route path="/dynamit" element={<Dynamit />} />
      <Route path="/narvesen" element={<Narvesen />} />

      <Route path="/admin" element={<Admin />} />
    </Routes>
  </BrowserRouter>,
  // </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
