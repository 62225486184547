import cans from "../../assets/images/cans.png";
import new_tastes from "../../assets/images/jaunas_garsas_uzlade.png";
import prizes_image from "../../assets/images/prizes_image.png";
import { Wrapper } from "./MainTitle.styled";

export const MainTitle = () => {
  return (
    <Wrapper>
      <div className="flex flex-col lg:flex-row lg:justify-evenly items-center justify-center">
        <div className="div__cans-image">
          <img src={cans} alt="Dynamit" />
        </div>
        <div className="div__main-title-info">
          <img
            className="img__new-tastes"
            src={new_tastes}
            alt="Jaunas garšas uzlādē!"
          />
          <h1>REĢISTRĒJIES UN LAIMĒ!</h1>
          <img className="img__prizes-image" src={prizes_image} alt="Balvas" />
          <div className="div__prizes-list">
            <div className="div__inner-column">
              <h4>
                <span className="span__number">1</span>MICROSOFT XBOX
              </h4>
              <h4>
                <span className="span__number">5</span>JBL TUMBIŅAS
              </h4>
            </div>
            <div className="div__inner-column">
              <h4>
                <span className="span__number">9</span>MUGURSOMAS
              </h4>
              <h4>
                <span className="span__number">6</span>AUKSTUMA SOMAS
              </h4>
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};
