import Main from "../../components/Main";
import { SelectorButton } from "../selector-button";

export default function Narvesen() {
  return (
    <>
      <SelectorButton />

      <div className='bg-narvesenBg h-full w-full bg-cover bg-no-repeat bg-center'>
        <Main />
        <div
          className='w-full py-8 flex justify-center items-center italic'
          style={{ fontFamily: "Inter" }}
        >
          <a
            href='https://integrators.lv?utm_source=dynamit.lv'
            target='_blank'
            rel='noreferrer'
            className='font-bold hover:text-blue-600 transition-colors'
          >
            Izstrādāja INTEGRATORS.lv
          </a>
        </div>
      </div>
    </>
  );
}
