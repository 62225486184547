import axios from "axios";
import React from "react";
import logo from "../../assets/images/dynamit_logo.png";
import { API_BASE_URL } from "../../config";
import { Title } from "./Winners.styled";
export const Winners = () => {
  const [data, setData] = React.useState({
    xbox: { title: "MICROSOFT XBOX", winners: [] },
    jbl: { title: "JBL BEZVADU AUSTIŅAS", winners: [] },
    bag: { title: "AUKSTUMA SOMAS", winners: [] },
    backpack: { title: "MUGURSOMAS", winners: [] },
  });

  const loadData = async () => {
    try {
      const { data: res } = await axios.get(
        `${API_BASE_URL}/dynamit/winner/list`
      );

      setData({
        xbox: { title: "MICROSOFT XBOX", winners: res.data.xbox },
        jbl: { title: "JBL TUMBIŅU", winners: res.data.jbl },
        bag: {
          title: "AUKSTUMA SOMAS",
          winners: res.data.bag,
        },
        backpack: { title: "MUGURSOMAS", winners: res.data.backpack },
      });
    } catch (err: any) {
      console.error("ERROR: loadData:", err);
    }
  };

  React.useEffect(() => {
    loadData();
  }, []);

  return (
    <div id="winners" className="flex flex-col items-center justify-center">
      <Title className="text-4xl">LAIMĒTĀJI</Title>
      <div className="px-8 mx-auto flex flex-col gap-12 py-8 mb-8">
        {Object.values(data).map((item) => (
          <div className="text-center">
            <p className="uppercase font-UniSansHeavyCAPS text-yel text-2xl pb-2">
              {item.title} laimējuši:
            </p>
            {!item.winners.length ? (
              <p className="text-xl font-PFDinTextCompPro-Regular">
                Laimētāji vēl nav izvēlēti
              </p>
            ) : (
              <div>
                {item.winners.map((winner: any) => (
                  <p className="capitalize text-lg text-self font-PFDinTextCompPro-Regular">
                    {winner.name}
                  </p>
                ))}
              </div>
            )}
          </div>
        ))}
      </div>

      <img src={logo} alt="Dynamit" className="max-w-[300px]"/>
    </div>
  );
};
