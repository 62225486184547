import Dates from "../Dates";
import MainTitle from "../MainTitle";
import Navbar from "../Navbar";
import Prizes from "../Prizes";
import Winners from "../Winners";

import moment from "moment";
import React from "react";
import { useNavigate } from "react-router-dom";
import { dates } from "../Dates/Dates.component";
import RegisterNew from "../Register/Register.new";
import { Wrapper } from "./Main.styled";

export const Main = () => {
  const navigate = useNavigate();

  // const narvesenStart = moment('03.04.2022', 'DD.MM.YYYY').valueOf();
  // const narvesenEnd = moment('01.05.2022', 'DD.MM.YYYY').valueOf();

  // const virsiStart = moment('28.04.2022', 'DD.MM.YYYY').valueOf();
  // const virsiEnd = moment('26.05.2022', 'DD.MM.YYYY').valueOf();

  // const rimiStart = moment('03.05.2022', 'DD.MM.YYYY').valueOf();
  // const rimiEnd = moment('17.05.2022', 'DD.MM.YYYY').valueOf();

  // const maximaStart = moment('31.05.2022', 'DD.MM.YYYY').valueOf();
  // const maximaEnd = moment('14.06.2022', 'DD.MM.YYYY').valueOf();

  // const circlekStart = moment('02.06.2022', 'DD.MM.YYYY').valueOf();
  // const circlekEnd = moment('21.07.2022', 'DD.MM.YYYY').valueOf();

  // const viadaStart = moment('06.09.2022', 'DD.MM.YYYY').valueOf();
  // const viadaEnd = moment('03.10.2022', 'DD.MM.YYYY').valueOf();

  const [started, setStarted] = React.useState<boolean>(false);

  React.useEffect(() => {
    let _s: boolean = false;
    dates.map((item) => {
      if (
        item.start.valueOf() <= moment.now() &&
        item.end.valueOf() >= moment.now() &&
        item.rules
      ) {
        navigate({
          // pathname: window.location.pathname,
          search: `?t=${item.name}`,
        });
        _s = true;
      }
    });
    if (!_s) {
      navigate({
        // pathname: window.location.pathname,
        search: `?t=not_started`,
      });
    }
    setStarted(_s);
    // if (narvesenStart <= moment.now() && narvesenEnd >= moment.now()) {
    // 	navigate({
    // 		pathname: '/',
    // 		search: '?t=narvesen',
    // 	})
    // 	setStarted(true)
    // } else if (virsiStart <= moment.now() && virsiEnd >= moment.now()) {
    // 	navigate({
    // 		pathname: '/',
    // 		search: '?t=virsi',
    // 	})
    // 	setStarted(true)
    // } else if (rimiStart <= moment.now() && rimiEnd >= moment.now()) {
    // 	navigate({
    // 		pathname: '/',
    // 		search: '?t=rimi',
    // 	})
    // 	setStarted(true)
    // } else if (maximaStart <= moment.now() && maximaEnd >= moment.now()) {
    // 	navigate({
    // 		pathname: '/',
    // 		search: '?t=maxima',
    // 	})
    // 	setStarted(true)
    // } else if (circlekStart <= moment.now() && circlekEnd >= moment.now()) {
    // 	navigate({
    // 		pathname: '/',
    // 		search: '?t=circlek',
    // 	})
    // 	setStarted(true)
    // } else if (viadaStart <= moment.now() && viadaEnd >= moment.now()) {
    // 	navigate({
    // 		pathname: '/',
    // 		search: '?t=viada',
    // 	})
    // 	setStarted(true)
    // } else {
    // 	navigate({
    // 		pathname: '/',
    // 		search: '?t=not_started',
    // 	})
    // 	setStarted(false)
    // }
  }, []);

  return (
    <Wrapper>
      <Navbar />
      <MainTitle />
      <Dates />
      {/* {started && <Register />} */}
      <RegisterNew />
      <Prizes />
      <Winners />
    </Wrapper>
  );
};
