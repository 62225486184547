import styled from 'styled-components';

const size = {
	xs: '320px',
	md: '768px',
	sm: '960px',
	lg: '1200px',
};

const device = {
	xs: `(min-width: ${size.xs})`,
	md: `(min-width: ${size.md})`,
	sm: `(min-width: ${size.sm})`,
	lg: `(min-width: ${size.lg})`
};

// export const Sample = styled.div`
// 	@media only screen and ${device.xs}{
// 	}
// 	@media only screen and ${device.sm}{	
// 	}
// 	@media only screen and ${device.lg}{	
// 	}
// `;

export const Wrapper = styled.div`
	margin: 100px 0px 0px 0px;
	.div__winner-box {
		display: flex;
		flex-wrap: wrap;
		width: 80%;
		margin: 0px auto;

		.div__winner-list {
			flex: 50%;
			width: 100%;
			display: flex;
			// justify-content: center;
			flex-direction: column;
			align-items: center;
			margin: 0px 0px 80px 0px;
			h2 {
				color: #fef200;
				font-size: 22px;
				text-align: center;
				width: 300px;
				margin: 20px 0px 30px 0px;
				font-family: UniSansHeavyCAPS;
			}
			.div__winner-columns {
				display: flex;
				flex-direction: row;
				.p__no-winners {
					font-family: PFDinTextCompPro-Regular;
					color: #fef200;
					font-size: 22px;
					margin: 0px 0px 15px 0px;	
				}
				.div__winner-column {
					p {
						font-family: PFDinTextCompPro-Regular;
						color: #fef200;
						font-size: 22px;
						margin: 0px 0px 15px 0px;
					}

					&:nth-child(1) {
						margin: 0px 60px 0px 0px;
					}
				}
			}
			img {
				margin: 50px 0px 0px 0px;
			}
		}
	}

	@media only screen and ${device.xs}{
	}
	@media only screen and ${device.sm}{	
		margin: 100px 0px 0px 0px;
		.div__winner-box {
			display: flex;
			flex-wrap: wrap;
			width: 80%;
			margin: 0px auto;

			.div__winner-list {
				flex: 50%;
				width: 100%;
				display: flex;
				// justify-content: center;
				flex-direction: column;
				align-items: center;
				margin: 0px 0px 80px 0px;
				h2 {
					color: #fef200;
					font-size: 30px;
					text-align: center;
					width: 300px;
					margin: 20px 0px 30px 0px;
					font-family: UniSansHeavyCAPS;
				}
				.div__winner-columns {
					display: flex;
					flex-direction: row;
					.div__winner-column {
						margin: 0px 15px 0px 0px;
						p {
							font-family: PFDinTextCompPro-Regular;
							color: #fef200;
							font-size: 22px;
							margin: 0px 15px 20px 0px;
						}
						&:nth-child(1) {
							margin: 0px 15px 0px 0px;
						}
					}
				}
				img {
					margin: 50px 0px 0px 0px;
				}
			}
		}
	}
	@media only screen and ${device.lg}{	
	}
`;

export const Title = styled.h1`
	font-family: PFDinTextCompPro-Regular;
	color: #fef200;
	background: #000000; 
	width: fit-content;
	padding: 17px 100px;
	margin: 0px auto 30px auto;
	
	@media only screen and ${device.xs}{
	}
	@media only screen and ${device.sm}{	
	}
	@media only screen and ${device.lg}{	
	}
`;

