import { Wrapper } from "./Navbar.styled";
import { Link } from "react-scroll";
import { useLocation } from "react-router-dom";
import React from "react";

import dynamit_logo from "../../assets/images/dynamit_logo.png";

import arrow_down from "../../assets/images/arrow_down.svg";

import { dates } from "../Dates/Dates.component";

export const Navbar = () => {
  const search = useLocation().search;
  const type = new URLSearchParams(search).get("t");
  const [file, setFile] = React.useState(undefined);
  const [navOpen, setNavOpen] = React.useState(false);

  React.useEffect(() => {
    dates.map((item) => item.name === type && setFile(item.rules));
  }, []);

  return (
    <Wrapper>
      <img src={dynamit_logo} alt="Dynamit Logo" />
      <ul>
        <li style={{ opacity: type === "not_started" ? 0.5 : 1 }}>
          <Link
            disabled={type === "not_started" ? true : false}
            to="register"
            spy={true}
            smooth={true}
            offset={-50}
            duration={500}
            delay={50}
          >
            REĢISTRĒTIES
          </Link>
        </li>
        <li>
          <Link
            to="prizes"
            spy={true}
            smooth={true}
            offset={-50}
            duration={500}
            delay={50}
          >
            BALVAS
          </Link>
        </li>
        <li>
          <Link
            to="winners"
            spy={true}
            smooth={true}
            offset={-50}
            duration={500}
            delay={50}
          >
            LAIMĒTĀJI
          </Link>
        </li>
        <li
          onClick={() => {
            setNavOpen(!navOpen);
          }}
          className="li__nav-rules"
        >
          NOTEIKUMI
          <img
            style={{ transform: navOpen ? "rotate(-180deg)" : "rotate(0deg)" }}
            className="img__navbar-dropdown"
            src={arrow_down}
            alt="Noteikumi"
          />
          {navOpen && (
            <div className="div__rule-dropdown">
              <ul>
                {dates.map((item) => (
                  <li>
                    <a
                      href={item.rules}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {item.title}
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          )}
        </li>
      </ul>
    </Wrapper>
  );
};
