import styled from 'styled-components';

const size = {
	xs: '320px',
	md: '768px',
	sm: '960px',
	lg: '1200px',
};

const device = {
	xs: `(min-width: ${size.xs})`,
	md: `(min-width: ${size.md})`,
	sm: `(min-width: ${size.sm})`,
	lg: `(min-width: ${size.lg})`
};

// export const Sample = styled.div`
// 	@media only screen and ${device.xs}{
// 	}
// 	@media only screen and ${device.sm}{	
// 	}
// 	@media only screen and ${device.lg}{	
// 	}
// `;

export const Wrapper = styled.div`
	padding: 50px 30px;
	@media only screen and ${device.xs}{
	}
	@media only screen and ${device.sm}{	
		padding: 90px 100px;
	}
	@media only screen and ${device.lg}{	
	}
`;


