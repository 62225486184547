import { useNavigate } from "react-router-dom";
import cans from "../assets/images/cans.png";
import caps from "../assets/images/dy_caps.png";
import narvesen from "../assets/images/narvesen_logo_2.png";
const selectors = [
  {
    className: "bg-dynamitSelector",
    title: "Krāšanas akcija",
    path: "/dynamit",
    date: "1.05. - 31.07.2024",
    logo: (
      <div className="flex flex-row items-center gap-4 mb-6">
        <img src={cans} alt={"Dynamit"} className={`w-[60px] md:w-[120px] h-auto`} />
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
          className="stroke-yel stroke-[3px] h-6 w-6 fill-yel"
        >
          <polygon points="6 3 20 12 6 21 6 3" />
        </svg>
        <img src={caps} alt={"Caps"} className={`w-[60px] md:w-[120px] h-auto`} />
      </div>
    ),
    shadow: "",
    button: ""
  },
  {
    className: "bg-narvesenSelector",
    title: "Akcija",
    path: "/narvesen",
    date: "31.03. - 27.04.2024",
    logo: (
      <img src={narvesen} alt={"Narvesen"} className={`w-[100px] md:w-[200px] h-auto mb-2`} />
    ),
    shadow: "",
    button: ""
  },
];

export default function Selector() {
  const navigate = useNavigate();
  return (
    <div className="flex flex-col h-screen lg:flex-row">
      {selectors.map(
        ({ className, title, path, date, logo, shadow, button }, index) => {
          const Logo = () => logo;

          return (
            <>
              <div className="w-full h-1/2 lg:w-1/2 lg:h-full">
                <div
                  className={`relative h-full flex flex-col items-center justify-center bg-cover bg-center bg-no-repeat bg-opacity-50 ${className}`}
                >
                  <div className="w-full h-full bg-black bg-opacity-60" />

                  <div
                    className={`absolute mx-8 w-[250px] h-[300px] md:h-[450px] md:w-[350px] flex flex-col items-center justify-center gap-6 bg-black rounded-2xl shadow-[inset_0px_0px_100px_rgb(255,237,0)]`}
                  >
                    <div className="text-center">
                      <div className="flex flex-col justify-center items-center gap-4">
                        <Logo />
                        <h1 className="text-xl font-UniSansHeavyItalicCAPS md:text-4xl">
                          {title}
                        </h1>
                      </div>
                      <p className="text-xs md:text-sm">{date}</p>
                    </div>
                    <button
                      onClick={() => navigate(path)}
                      className={`bg-yel px-4 py-2 text-black hover:bg-yel/70 active:bg-yel/70 transition-all md:px-8 md:py-4 ${button}`}
                    >
                      <span className="uppercase font-UniSansHeavyCAPS text-md md:text-xl">
                        Izvēlēties
                      </span>
                    </button>
                  </div>
                </div>
              </div>
              {index === 0 && (
                <span className="h-[1px] w-full lg:w-[1px] lg:h-full bg-gray-700" />
              )}
            </>
          );
        }
      )}
    </div>
  );
}
