import { useNavigate } from "react-router-dom";

export const SelectorButton = () => {
  const navigate = useNavigate();
  return (
    <div className="fixed top-0 left-0 bg-red z-[99999999]">
      <button
        onClick={() => navigate("/")}
        className="bg-yel rounded-br-lg hover:bg-yel/80 transition-colors px-4 py-2 flex flex-row items-center gap-1"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          stroke="black"
          stroke-width="4"
        >
          <path d="m18 15-6-6-6 6" />
        </svg>
        <span className="text-black relative top-[1px] text-lg font-UniSansHeavyCAPS">
          Izvēlne
        </span>
      </button>
    </div>
  );
};
