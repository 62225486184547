import styled from "styled-components";

const size = {
  xs: "320px",
  md: "768px",
  sm: "960px",
  lg: "1200px",
};

const device = {
  xs: `(min-width: ${size.xs})`,
  md: `(min-width: ${size.md})`,
  sm: `(min-width: ${size.sm})`,
  lg: `(min-width: ${size.lg})`,
};

// export const Sample = styled.div`
// 	@media only screen and ${device.xs}{
// 	}
// 	@media only screen and ${device.sm}{
// 	}
// 	@media only screen and ${device.lg}{
// 	}
// `;

export const Wrapper = styled.div`
  background-color: black;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  padding: 40px 0px;
  margin: 20px auto 0px auto;
  width: 300px;
  gap: 10px;

  .active {
    background-color: #fef20050;
    padding: 10px 0px 10px 0px;
  }
  .div__store-date {
    font-family: PFDinTextCompPro-Regular;
    color: #fef200;
    position: relative;
    margin: 0px 0px 30px 0px;
    display: flex;
    justify-content: center;
    align-items: center;

    .div__date-store-inner {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .a__date-store-register-button {
      top: 60px;
      background-color: #fef200;
      padding: 15px 40px;
      margin: 10px 0px 10px 0px;
      color: black;
      font-weight: bold;
      line-height: 0.9;
      cursor: pointer;
      transition: 0.15s ease-in-out;
      &:hover {
        font-size: 18px;
      }
    }

    &:nth-last-child(1) {
      padding-bottom: 0px;
      margin-bottom: 0px;
    }

    a {
      padding: 10px 0px 0px 0px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }

    h5 {
      font-size: 14px;
      width: fit-content;
    }
    img {
      max-height: 30px;
      margin-bottom: 10px;
    }
  }

  @media only screen and ${device.xs} {
  }
  @media only screen and ${device.sm} {
    background-color: black;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    padding: 15px 10px;
    margin: 50px auto 0px auto;
    width: 100%;

    .div__store-date {
      font-family: PFDinTextCompPro-Regular;
      color: #fef200;

      padding: 10px;
      margin: 0px;

      .div__date-store-inner {
        display: flex;
        flex-direction: column;
        align-items: center;
      }

      .a__date-store-register-button {
        top: 60px;
        background-color: #fef200;
        padding: 15px 40px;
        margin: 0px 0px 0px 10px;
        // border: 5px solid black;
        color: black;
        font-weight: bold;
      }

      a {
        padding: 0px 0px 0px 0px;
      }

      h5 {
        font-size: 14px;
        width: fit-content;
      }
      img {
        height: 40px;
      }
    }
  }
  @media only screen and ${device.lg} {
    background-color: black;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    padding: 16px 10px;
    margin: 50px auto 0px auto;
    width: 100%;

    .div__store-date {
      font-family: PFDinTextCompPro-Regular;
      color: #fef200;
      padding: 0px;

      .div__date-store-inner {
        display: flex;
        flex-direction: row;
        align-items: center;
      }

      &:nth-last-child(1) {
        // padding-bottom: 10px;
      }

      a {
        padding: 0px 10px 0px 0px;
        height: 40px;
        display: flex;
        align-items: center;
      }

      h5 {
        font-size: 16px;
        width: fit-content;
      }
      img {
        height: 40px;
        margin: 0px 0px 0px 0px;
      }
    }
    .active {
      padding: 12px;
      margin: 0px;
    }
  }
`;
